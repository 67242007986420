import React from "react";

import { graphql } from "gatsby";

import Vacancy from "../../components/pages/Vacancies/Vacancy";
import VacancyDataProvider from "../../providers/VacancyDataProvider";

const StellenageboteIndexPage = ({ pageContext: { id }, data: { page } }) => (
    <VacancyDataProvider>
        <Vacancy {...{ id, page }} />
    </VacancyDataProvider>
);
export default StellenageboteIndexPage;

export const query = graphql`
    query {
        page: pageItemsYaml(templateKey: { eq: "pageItems/stellenangebot" }) {
            ...PageItem
        }
    }
`;
